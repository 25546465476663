import React from "react";
import {
  Blue1,
  Blue10,
  Blue11,
  Blue12,
  Blue13,
  Blue14,
  Blue2,
  Blue3,
  Blue4,
  Blue5,
  Blue6,
  Blue7,
  Blue8,
  Blue9,
} from "../../assets/images";
import MainLayout from "../../layouts/mainLayout";

type Props = {};

const NimbusBlue = (props: Props) => {
  //change page title
  React.useEffect(() => {
    document.title = "quyvt.dev | Nimbus Blue";
  }, []);

  const details = [
    {
      title: "Timeframes",
      description: "Since 2021",
    },
    {
      title: "Business sector",
      description: "Office total-cleaning services",
    },
    {
      title: "My Roles",
      description: (
        <div className="flex flex-col gap-4">
          <p>Web development</p>
          <p>Mobile development</p>
        </div>
      ),
    },
    {
      title: "Technologies",
      description: "Typescript, React, GraphQL, Elixir",
    },
    {
      title: "Team size",
      description: (
        <div className="flex flex-col gap-4">
          <p>2 x Backend developers</p>
          <p>2 x Frontend developers</p>
          <p>1 x UI/UX designer</p>
          <p>1 x Scrum Master</p>
          <p>1 x QA specialists</p>
        </div>
      ),
    },
  ];
  return (
    <MainLayout>
      <div className="relative">
        <section
          className="lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <div className="text-6xl lg:text-9xl font-semibold text-dark lg:tracking-wider text-center">
            Blue dashboard
          </div>

          <div className="mt-12 lg:mb-0 mb-20">
            <div className="lg:text-2xl text-xl tracking-wide leading-10 text-center">
              Frontline Operations Reimagined
            </div>
          </div>
        </section>

        <section
          className="lg:mt-40 mt-2"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <img src={Blue1} alt="illustration" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Details</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              {details.map((item, index) => (
                <div
                  className={`grid lg:grid-cols-12 grid-cols-1 gap-10 ${
                    index !== details.length - 1 ? "divider" : "pt-8"
                  }`}
                  key={index}
                >
                  <div className="text-lg tracking-wide leading-8 font-semibold col-span-4">
                    {item.title}
                  </div>
                  <div className="text-content">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          className="bg-white py-20 px-20"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>

        <section
          className="bg-primary lg:pt-20 lg:pb-10 lg:px-20 pt-20 px-10 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="flex lg:flex-row flex-col justify-between">
            <div>
              <div className="lg:text-3xl text-2xl">
                Nimbus Blue is a holistic technology system that provides
                efficiency for every single layer of your business, from
                Operations and HR, to Account Management and even Business
                Intelligence.
                <br />
                <br />
                Your all-in-one workforce management, HR and payroll software
                for manpower businesses worldwide.
                <br />
                <br />
                For the very first time, we just use this platform to manage our
                own business. After that we are very excited to expand to bring
                this product to the market and help other businesses to improve
                their efficiency.
              </div>
            </div>
          </div>
          <img src={Blue2} alt="illustration" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">About technologies</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                One super dashboard with backend coded by Elixir
              </div>

              <ul className="list-disc text-content lg:ml-20 ml-4 mt-8">
                <li className="mb-8">
                  Typescript used for whole projects. We decided to use because
                  it's a superset of Javascript and it is a great tool for large
                  scale projects.
                </li>
                <li className="mb-8">
                  React is the main library UI for dashboard.
                </li>
                <li className="mb-8">
                  Apollo Client is used for GraphQL queries and mutations.
                </li>
                <li className="mb-8">
                  Tailwind CSS is used when project is started. But we decided
                  to change to Bootstrap 5 after some phases.
                </li>
                <li className="mb-8">Lottie – graphic animations.</li>
                <li className="mb-8">
                  Google Maps Platform APIs, Firebase also included.
                </li>
              </ul>
              {/* <div className="text-content">
                Manage your whole frontline operations at your fingertips
                without being on-site. Create rosters and deploy your workers
                easily without having to constantly check on the workers’
                availability, or whether they are indeed on-site for their work.
                <br />
                <br />
                We created two-flexible view modes for the job deployment, from
                there the user can easily to check the workers’ status,
                availability and make the deployment for new job request.
              </div> */}
            </div>
          </div>
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Project’s highlights</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Operation & Deployment
              </div>
              <div className="text-content">
                Manage your whole frontline operations at your fingertips
                without being on-site. Create rosters and deploy your workers
                easily without having to constantly check on the workers’
                availability, or whether they are indeed on-site for their work.
                <br />
                <br />
                We created two-flexible view modes for the job deployment, from
                there the user can easily to check the workers’ status,
                availability and make the deployment for new job request.
              </div>
            </div>
          </div>
          <img src={Blue3} alt="blue-mockup-3" className="mt-8" />
          <img src={Blue4} alt="blue-mockup-3" className="mt-8" />
          <img src={Blue5} alt="blue-mockup-3" className="mt-8" />

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Human Resources & Payroll
              </div>
              <div className="text-content">
                Simplify the complexities of HR & payroll with the digitized
                timesheets, leaves and claims management system, leaving users
                with more time to focus on the wellness of the employees.
              </div>
            </div>
          </div>
          <img src={Blue8} alt="blue-mockup-3" className="mt-8" />
          <img src={Blue7} alt="blue-mockup-3" className="mt-8" />
          <div className="grid lg:grid-cols-2 gap-10">
            <img src={Blue9} alt="blue-mockup-3" className="mt-8" />
            <img src={Blue10} alt="blue-mockup-3" className="mt-8" />
          </div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Account Management
              </div>
              <div className="text-content">
                Skip the need for emails or WhatsApp conversations by automating
                your checklists and reports! The comprehensive system also
                allows your clients to be notified of the attendance and
                replacements of workers deployed to them.
              </div>
            </div>
          </div>
          <img src={Blue11} alt="blue-mockup-3" className="mt-8" />
          <img src={Blue12} alt="blue-mockup-3" className="mt-8" />
          <img src={Blue13} alt="blue-mockup-3" className="mt-8" />

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                And business intelligence
              </div>
              <div className="text-content">
                Get a good overview of the business overall health. Identify the
                best performing and worst performing sites, and learn how we can
                optimise overall operations in order to continue growing the
                business.
              </div>
            </div>
          </div>
          <img src={Blue14} alt="blue-mockup-3" className="mt-8" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading mb-20">Final thoughts</div>
          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                My Challenges & Solutions
              </div>
              <div className="text-content">
                For the very first time, Blue has a old name is "Nimbus" and
                this project just using for internal use. So the team size is
                very small: Just me and my backend leader. We have to do all to
                make the company business run with technical-support.
                <br />
                <br />
                Then one day, after see the company business is growing, we
                decide to bring that project to the next level: Make it become
                SaaS. That's why this requires the technical team to do a lot of
                work.
                <br />
                <br />
                So I have to do a lot of things: From the design, to coding but
                I feel very happy to see the result after that. We have a lot of
                clients buying our product and we have a lot of feedback from
                the end user. Then we have a lot of new features to add to the
                app, and we also have many member join our team.
                <br />
                <br />
                After collapse with the solid tech team 2 years. I have a lot of
                experience in React, Typescript, coding convention more
                improved. Solved a number of technical problems. Besides that,
                with the CEO strong vision, I also learn how the SaaS business
                is, how we can we make the end-user pleasant with our product.
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-white pt-40 px-20 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>
      </div>
    </MainLayout>
  );
};

export default NimbusBlue;
