import React from "react";
import { useNavigate } from "react-router-dom";
import { MyLogo, SmallLogo } from "../assets/images";
import { motion } from "framer-motion";

type Props = {
  children: React.ReactNode;
};

const socialLinks = [
  {
    name: "Phone",
    link: "tel:+84343062244",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-phone text-dark hover:text-primary hoverable"
      >
        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
      </svg>
    ),
  },
  {
    name: "Email",
    link: "mailto:hello@quyvt.dev",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-mail text-dark  hover:text-primary hoverable"
      >
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
        <polyline points="22,6 12,13 2,6"></polyline>
      </svg>
    ),
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/vtquy98/",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-mail text-dark hover:text-primary hoverable"
      >
        <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
        <rect x="2" y="9" width="4" height="12"></rect>
        <circle cx="4" cy="4" r="2"></circle>
      </svg>
    ),
  },
  {
    name: "Github",
    link: "https://github.com/vtquy98/",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-github text-dark hover:text-primary hoverable"
      >
        <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
      </svg>
    ),
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/quyvt.dev/",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-facebook text-dark hover:text-primary hoverable"
      >
        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
      </svg>
    ),
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/quyvt.dev/",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-instagram text-dark hover:text-primary hoverable"
      >
        <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
      </svg>
    ),
  },
];

const MainLayout: React.FC<Props> = ({ children }) => {
  const navigation = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div exit={{ opacity: 0 }}>
      <div className="flex lg:flex-row flex-col">
        <aside className="lg:h-screen sticky bg-white lg:p-4 top-0 overflow-hidden z-40">
          <div className="flex lg:flex-col flex-row items-center justify-between lg:p-6 py-4 lg:px-8 px-10 lg:h-screen relative">
            <img
              src={SmallLogo}
              alt="logo"
              className="w-10"
              onClick={() => navigation("/")}
            />
            <a
              href="mailto:hello@quyvt.dev"
              className="absolute right-10 lg:bottom-20 lg:right-0 lg:left-0 lg:w-30 w-30"
            >
              <div className="lg:-rotate-90 text-xl c-link-decor tracking-wide leading-10 hoverable">
                Let's Talk!
              </div>
            </a>
          </div>
        </aside>
        <main className="bg-white w-full">
          {children}
          <footer
            className="pb-20 flex flex-col lg:flex-row justify-between lg:px-20 px-10"
            style={{
              zIndex: 2,
              position: "sticky",
            }}
          >
            <div className="flex flex-col bg-white lg:order-1 order-2 items-center">
              <img src={MyLogo} alt="logo" width={300} />
              <div className="text-sm text-dark max-w-2xl mt-8 subpixel-antialiased">
                © quyvt.dev All rights reserved 2022.
              </div>
            </div>
            {/* flex lg:gap-12 lg:order-2 order-1 lg:mb-0 mb-10 lg:justify-center justify-around lg:px-0 px-20 */}
            <div className="flex lg:order-2 order-1 lg:mb-0 mb-10 justify-around lg:gap-12">
              {socialLinks.map((l) => (
                <a href={l.link} key={l.name}>
                  {l.icon}
                </a>
              ))}
            </div>
          </footer>
        </main>
      </div>
    </motion.div>
  );
};

export default MainLayout;
