import React from "react";
import { TweenMax } from "gsap/all";
import useIsMobile from "../utils/useIsMobile";

import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {
  Error,
  CoachDashboardPage,
  Home3,
  NimbusBlue,
  CrewApp,
  GetFulfilled,
} from "../pages";

const MainRoutes = () => {
  // Custom cursor

  const isMobile = useIsMobile();

  React.useEffect(() => {
    if (isMobile) {
      return;
    }

    const $bigBall = document.querySelector(".cursor__ball--big");
    const $smallBall = document.querySelector(".cursor__ball--small");
    const $hoverables = document.querySelectorAll(".hoverable");

    // Listeners
    document.body.addEventListener("mousemove", onMouseMove);
    for (let i = 0; i < $hoverables.length; i++) {
      $hoverables[i].addEventListener("mouseenter", onMouseHover);
      $hoverables[i].addEventListener("mouseleave", onMouseHoverOut);
    }

    // Move the cursor
    function onMouseMove(e) {
      TweenMax.to($bigBall, 0.7, {
        x: e.pageX - 15,
        y: e.pageY - 15,
      });
      TweenMax.to($smallBall, 0.1, {
        x: e.pageX - 5,
        y: e.pageY - 7,
      });
    }

    // Hover an element
    function onMouseHover() {
      TweenMax.to($bigBall, 0.3, {
        scale: 2,
      });
    }
    function onMouseHoverOut() {
      TweenMax.to($bigBall, 0.3, {
        scale: 1,
      });
    }
  }, [isMobile]);

  const location = useLocation();

  return (
    <>
      {!isMobile && (
        <div className="cursor">
          <div className="cursor__ball cursor__ball--big">
            <svg height="30" width="30">
              <circle cx="15" cy="15" r="12" strokeWidth="0"></circle>
            </svg>
          </div>

          <div className="cursor__ball cursor__ball--small">
            <svg height="10" width="10">
              <circle cx="5" cy="5" r="4" strokeWidth="0"></circle>
            </svg>
          </div>
        </div>
      )}

      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home3 />} />
          <Route path="*" element={<Error />} />

          <Route path="/case/nimbus-blue" element={<NimbusBlue />} />
          <Route path="/case/crew-application" element={<CrewApp />} />
          <Route path="/case/get-fulfilled" element={<GetFulfilled />} />
          <Route
            path="/case/coach-dashboard"
            element={<CoachDashboardPage />}
          />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default MainRoutes;
