import React from "react";
import {
  Ff1,
  Ff10,
  Ff11,
  Ff12,
  Ff2,
  Ff3,
  Ff4,
  Ff5,
  Ff6,
  Ff7,
  Ff8,
  Ff9,
} from "../../assets/images";
import MainLayout from "../../layouts/mainLayout";

type Props = {};

const CoachDashboardPage = (props: Props) => {
  //change page title
  React.useEffect(() => {
    document.title = "quyvt.dev | Coach Dashboard";
  }, []);

  const details = [
    {
      title: "Timeframes",
      description: "Since 2020",
    },
    {
      title: "Business sector",
      description: "Online Coaching and Training",
    },
    {
      title: "My Roles",
      description: (
        <div className="flex flex-col gap-4">
          <p>Frontend development</p>
        </div>
      ),
    },
    {
      title: "Technologies",
      description: "Javascript, React, Redux",
    },
    {
      title: "Team size",
      description: (
        <div className="flex flex-col gap-4">
          <p>1 x Frontend developers</p>
          <p>1 x Backend developers</p>
          <p>1 x Mobile developer</p>
          <p>1 x UI/UX designer</p>
          <p>1 x Scrum Master</p>
          <p>1 x QA specialists</p>
        </div>
      ),
    },
  ];
  return (
    <MainLayout>
      <div className="relative">
        <section
          className="lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <div className="text-6xl lg:text-9xl font-semibold text-dark lg:tracking-wider text-center">
            Fulfilled Coach Dashboard
          </div>

          <div className="mt-12 lg:mb-0 mb-20">
            <div className="lg:text-2xl text-xl tracking-wide leading-10 text-center">
              Start your coaching and training with technology
            </div>
          </div>
        </section>

        <section
          className="lg:mt-40 mt-2"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <img src={Ff1} alt="illustration" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Details</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              {details.map((item, index) => (
                <div
                  className={`grid lg:grid-cols-12 grid-cols-1 gap-10 ${
                    index !== details.length - 1 ? "divider" : "pt-8"
                  }`}
                  key={index}
                >
                  <div className="text-lg tracking-wide leading-8 font-semibold col-span-4">
                    {item.title}
                  </div>
                  <div className="text-content">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          className="bg-white py-20 px-20"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>

        <section
          className="bg-primary lg:pt-20 lg:pb-10 lg:px-20 pt-20 px-10 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="flex lg:flex-row flex-col justify-between">
            <div>
              <div className="lg:text-3xl text-2xl">
                Fulfilled’s suite of easy online tools allows coaches to offer
                customised courses, coaching chats and private, safe communities
                for peer support and learning, thus taking client engagement
                beyond traditional face-to-face meetings and into a mobile
                experience. Turn your coaching and training into a unique,
                scalable online experience
              </div>
            </div>
          </div>
          <img src={Ff2} alt="illustration" className="mt-20" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">About technologies</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-end-10 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                An dashboard for Coaches and Trainers to manage their clients
                and courses online coded with React and Redux with backend API
                in RoR.
              </div>

              <ul className="list-disc text-content lg:ml-20 ml-4 mt-8">
                <li className="mb-8">
                  Javascript with functional programming used for whole project.
                </li>
                <li className="mb-8">
                  React is the main library UI for dashboard.
                </li>
                <li className="mb-8">
                  Redux Observable is used for state management.
                </li>
                <li className="mb-8">Lottie – graphic animations.</li>
                <li className="mb-8">
                  Google Maps Platform APIs, Firebase also included.
                </li>
                <li className="mb-8">Stripe API for payment processing.</li>
              </ul>
            </div>
          </div>
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Project’s highlights</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-content mb-4">
                The Coach dashboard is a web application for coaches and
                trainers to manage their job online. In this post I just share
                some highlights of the dashboard for our client. The app for
                end-user{" "}
                <span className="text-primary font-semibold">
                  could be found here.
                </span>{" "}
                So with the Fulfilled Coach Dashboard we provide:
              </div>

              <div className="text-content">
                <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                  A new way to nurture your clients
                </div>
                Fulfilled is a way to give your clients a rich coaching and
                training experience through a mobile app on their phone by
                <ul className="list-disc text-content lg:ml-20 ml-4 mt-8">
                  <li className="mb-8">
                    The client can do online courses designed by them - on their
                    own or as a group - You can pop in with feedback at any
                    point.
                  </li>
                  <li className="mb-8">
                    The client's end users will learn from each other in a
                    private, safe community feed - Of course, you can be part of
                    the conversation.
                  </li>
                  <li className="mb-8">
                    The client's end users will feel a personalised connection
                    with you through one-on-one chats - You can initiate chats
                    at your convenience.
                  </li>
                </ul>
                {/* <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                  A designed for impact with ease
                </div>
                My team understand the time and care that goes into effective
                coaching and training. So we designed the product to take care
                of the routine tasks for you. Everything just on your phone or
                desktop with less-effort.
                <ul className="list-disc text-content ml-20 mt-8">
                  <li className="mb-8">
                    Everything is Automation - Fulfilled automates course
                    delivery, along with client reminders, onboarding, payments
                    and more. You’ll only interact with clients on the
                    meaningful stuff.
                  </li>
                  <li className="mb-8">
                    Curation - Fufiilled provides you a curated to-do list every
                    day, so you won’t miss anything requiring your response.
                    You’ll only need a few clicks to check off the items.
                  </li>
                  <li className="mb-8">
                    Tracking - Fulfilled will track clients’ progress for you,
                    along with other essentials like appointment bookings and
                    sales figures. You’ll get insights to help you grow your
                    business.
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <img src={Ff3} alt="blue-mockup-3" className="mt-8" />
          <img src={Ff6} alt="blue-mockup-3" className="mt-8" />
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
            <img src={Ff4} alt="blue-mockup-3" className="mt-8" />
            <img src={Ff5} alt="blue-mockup-3" className="mt-8" />
          </div>
          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-content">
                <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                  A designed for impact with ease
                </div>
                My team understand the time and care that goes into effective
                coaching and training. So we designed the product to take care
                of the routine tasks for you. Everything just on your phone or
                desktop with less-effort.
                <ul className="list-disc text-content lg:ml-20 ml-4 mt-8">
                  <li className="mb-8">
                    Everything is Automation - Fulfilled automates course
                    delivery, along with client reminders, onboarding, payments
                    and more. You’ll only interact with clients on the
                    meaningful stuff.
                  </li>
                  <li className="mb-8">
                    Curation - Fufiilled provides you a curated to-do list every
                    day, so you won’t miss anything requiring your response.
                    You’ll only need a few clicks to check off the items.
                  </li>
                  <li className="mb-8">
                    Tracking - Fulfilled will track clients’ progress for you,
                    along with other essentials like appointment bookings and
                    sales figures. You’ll get insights to help you grow your
                    business.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <img src={Ff7} alt="blue-mockup-3" className="mt-8" />
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
            <img src={Ff8} alt="blue-mockup-3" className="mt-8" />
            <img src={Ff9} alt="blue-mockup-3" className="mt-8" />
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
            <img src={Ff10} alt="blue-mockup-3" className="mt-8" />
            <img src={Ff11} alt="blue-mockup-3" className="mt-8" />
          </div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-content">
                <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                  Fully customisable
                </div>
                One of the most important things that I like about Fulfilled
                most is allow the customer to customise base on their brand.
                <ul className="list-disc text-content lg:ml-20 ml-4 mt-8">
                  <li className="mb-8">
                    First, we allow the customer to customise the app for
                    their's users. They can change the logo, the color, the
                    font, even the orientation of the app.
                  </li>
                  <li className="mb-8">
                    Second, each course/challenge the customer can fully
                    customise to make it fit their purpose. They can add
                    components like video, audio, text, image, quiz, etc. Also
                    each challenge or campaign for end-user has a landing page
                    for user sign up, and of course, the they can customise the
                    landing page as well.
                  </li>
                  <li className="mb-8">
                    More than that, the customer can customise the automation
                    email template to make it fit their brand, more
                    professional. A wonderful thing that is the customer just
                    drag-and-drop everything, less effort and more time to focus
                    on the important stuff.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img src={Ff12} alt="blue-mockup-3" className="mt-8" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading mb-20">Final thoughts</div>
          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                My Challenges & Solutions
              </div>
              <div className="text-content">
                With great opportunities when I joined the Fulfilled early, I
                can know the business model and the product. I can also know the
                challenges and the solutions.
                <br />
                <br />
                The project gives me a chance to learn and practice my skills
                with a lot of complex component eg. building dymanic content for
                a lession, building new feed for the user,... And also learn
                other redux modal like redux-obervable that I've never used
                before, and as well as complex payment system integration.
                <br />
                <br />
                Beside that, in the context of the covid 2020 pandemic, the team
                can't hire more developer so I'm the only front end developer of
                the team. This leads to I need to do everything from coding to
                testing, deployment, even support on UI/UX design. It can be
                said that this project has brought me more knowledge and
                confidence with upcoming projects.
                <br />
                <br />
                I'm very happy to be a part of this project and I'm very proud
                of the product that I've built. We have a lot of clients in SG
                eg:{" "}
                <span className="text-primary font-semibold">
                  Garage Circuit, Ufit, R-fitness, Coach Felicia
                </span>{" "}
                and much more.
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-white pt-40 px-20 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>
      </div>
    </MainLayout>
  );
};

export default CoachDashboardPage;
