import React from "react";
import {
  Getff1,
  Getff10,
  Getff11,
  Getff12,
  Getff13,
  Getff14,
  Getff2,
  Getff3,
  Getff4,
  Getff5,
  Getff6,
  Getff7,
  Getff8,
  Getff9,
} from "../../assets/images";
import MainLayout from "../../layouts/mainLayout";

type Props = {};

const GetFulfilled = (props: Props) => {
  //change page title
  React.useEffect(() => {
    document.title = "quyvt.dev | Get Fulfilled Application";
  }, []);

  const details = [
    {
      title: "Timeframes",
      description: "Since 2020",
    },
    {
      title: "Business sector",
      description: "Online Coaching and Training",
    },
    {
      title: "My Roles",
      description: (
        <div className="flex flex-col gap-4">
          <p>Mobile development</p>
        </div>
      ),
    },
    {
      title: "Technologies",
      description: "Javascript, React Native, Redux toolkit, Ruby on rails",
    },
    {
      title: "Team size",
      description: (
        <div className="flex flex-col gap-4">
          <p>2 x Backend developers</p>
          <p>1 x Mobile developer</p>
          <p>1 x UI/UX designer</p>
          <p>1 x Scrum Master</p>
          <p>1 x QA specialists</p>
        </div>
      ),
    },
  ];
  return (
    <MainLayout>
      <div className="relative">
        <section
          className="lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <div className="text-6xl lg:text-9xl font-semibold text-dark lg:tracking-wider text-center">
            Get Fulfilled Application
          </div>

          <div className="mt-12 lg:mb-0 mb-20">
            <div className="lg:text-2xl text-xl tracking-wide leading-10 text-center">
              An experience of rich coaching and training experience
            </div>
          </div>
        </section>

        <section
          className="lg:mt-40 mt-2"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <img src={Getff1} alt="illustration" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Details</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              {details.map((item, index) => (
                <div
                  className={`grid lg:grid-cols-12 grid-cols-1 gap-10 ${
                    index !== details.length - 1 ? "divider" : "pt-8"
                  }`}
                  key={index}
                >
                  <div className="text-lg tracking-wide leading-8 font-semibold col-span-4">
                    {item.title}
                  </div>
                  <div className="text-content">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          className="bg-white py-20 px-20"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>

        <section
          className="bg-primary lg:pt-20 lg:pb-10 lg:px-20 pt-20 px-10 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="flex lg:flex-row flex-col justify-between">
            <div>
              <div className="lg:text-3xl text-2xl">
                Get Fulfilled Application is a mobile application that helps the
                user can get a rich coaching and training experience. Turn the
                exercise activities into a great experience just on the mobile
                phone.
                <br />
                <br />
                This is the first application that I have developed by leanring
                React Native from scratch and become to invididual contributor
                of the team.
              </div>
            </div>
          </div>
          <img src={Getff2} alt="illustration" className="mt-20" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">About technologies</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                An Get Fulfilled application and several apps for customer base
                on React Native with backend coded by RoR.
              </div>

              <ul className="list-disc text-content lg:ml-20 ml-4 mt-8">
                <li className="mb-8">
                  Javascript with functional programming used for whole project.
                </li>
                <li className="mb-8">
                  React native is the main library UI for the application.
                </li>
                <li className="mb-8">
                  Redux toolkit is used for state management.
                </li>
                <li className="mb-8">Lottie – graphic animations.</li>
                <li className="mb-8">
                  Google Maps Platform APIs, Firebase also included.
                </li>
                <li className="mb-8">Stripe API for payment processing.</li>
              </ul>
            </div>
          </div>
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Project’s highlights</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Library of trainning course
              </div>
              <div className="text-content">
                Thanks to the{" "}
                <span className="text-primary font-semibold">
                  Fulfilled Coach Dashboard
                </span>{" "}
                that I mentioned before. A rich system of training courses,
                challenges, and campaigns, created by the user's own trainers
                with diverse content, ensuring that the training hours are not
                boring as if the coach was with the user.
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-20">
            <img src={Getff3} alt="blue-mockup-3" className="mt-8" />
            <img src={Getff4} alt="blue-mockup-3" className="mt-8" />
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
            <img src={Getff5} alt="blue-mockup-3" className="mt-8" />
            <img src={Getff6} alt="blue-mockup-3" className="mt-8" />
          </div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Rich content of training course
              </div>
              <div className="text-content">
                I and the team tried to build the course system builder and make
                sure it work well and display the course content exactly what
                the coach want. Just so you can imagine it better, it's a form
                of WYSIWYG editor but every component builded from scratch.
                <br />
                <br />
                We created two types of main course content:{" "}
                <span className="text-primary font-semibold">Workout</span> and
                <span className="text-primary font-semibold">
                  {" "}
                  Non-Workout.
                </span>{" "}
                With Workout lession, the coach can create a workout with a set
                of video-exercises and we alos included the timer for each set
                of exercise to make sure the user can follow the coach's plan.
                <br />
                <br />
                With Non-Workout lession, the coach can create a lession with a
                set of content like text, image, video, audio, even a quiz or
                questionnaire to help their's coach to know more about their
                status of training and provide the best training plan for them.
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-20">
            <img src={Getff7} alt="blue-mockup-3" className="mt-8" />
            <img src={Getff8} alt="blue-mockup-3" className="mt-8" />
          </div>
          <img src={Getff9} alt="blue-mockup-3" className="mt-8" />
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
            <img
              src={Getff10}
              alt="blue-mockup-3"
              className="mt-8 grid-cols-4"
            />
            <img
              src={Getff11}
              alt="blue-mockup-3"
              className="mt-8 grid-cols-8"
            />
          </div>
          <img src={Getff12} alt="blue-mockup-3" className="mt-8" />
          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Easy purchase with friendly system of payment
              </div>
              <div className="text-content">
                Beside a lot of free content, the coach can also create a paid
                courses or exclusiveness campaigns for their's customer or even
                book a freelancer trainer. The team also built a system of
                payment with Stripe API to make sure the payment process is easy
                and secure.
              </div>
            </div>
          </div>
          <img src={Getff13} alt="blue-mockup-3" className="mt-8" />
          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                A great community for coach and customers
              </div>
              <div className="text-content">
                The team also built a system of community for coach and their's
                customer. The coach can create a group for their's customer and
                share their's training plan, tips, and even a group chat to help
                their's customer to follow the training plan as well as customer
                can share their's training progress and get support from other.
              </div>
            </div>
          </div>
          <img src={Getff14} alt="blue-mockup-3" className="mt-8" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading mb-20">Final thoughts</div>
          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                My Challenges & Solutions
              </div>
              <div className="text-content">
                The biggest challenge for me is to build a system of training
                and a system of payment. I can say that the Get Fulfilled
                project is the first one that I have to join and build by myself
                while learning React Native from scratch.
                <br />
                <br />I have to learn a lot of new things like how to build a
                system to intergrate with Payment system, how to build a system
                of training, community, and a lot of other things.
                <br />
                <br />
                Beside that, I also have to learn how to work with a team on
                Scrumban and how to communicate with the team and the client to
                receive the feedback and make sure the project is going well.
                <br />
                <br />
                More than that, by working on this project as an individual
                developer, I have to learn how to manage deployment to both the
                Google Play Store and the App Store. That is a great experience
                for me when I can directly check and fix the store's review. I
                think it will be a solid stepping stone for me to be more
                confident for the upcoming journey.
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-white pt-40 px-20 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>
      </div>
    </MainLayout>
  );
};

export default GetFulfilled;
