import React from "react";
import {
  Crew1,
  Crew10,
  Crew11,
  Crew12,
  Crew13,
  Crew14,
  Crew2,
  Crew3,
  Crew4,
  Crew5,
  Crew7,
  Crew8,
  Crew9,
} from "../../assets/images";
import MainLayout from "../../layouts/mainLayout";

type Props = {};

const CrewApp = (props: Props) => {
  //change page title
  React.useEffect(() => {
    document.title = "quyvt.dev | Crew application";
  }, []);

  const details = [
    {
      title: "Timeframes",
      description: "Since 2021",
    },
    {
      title: "Business sector",
      description: "Office total-cleaning services",
    },
    {
      title: "My Roles",
      description: (
        <div className="flex flex-col gap-4">
          <p>Mobile development</p>
        </div>
      ),
    },
    {
      title: "Technologies",
      description: "Typescript, React Native, Expo, GraphQL, Elixir",
    },
    {
      title: "Team size",
      description: (
        <div className="flex flex-col gap-4">
          <p>2 x Backend developers</p>
          <p>2 x Frontend developers</p>
          <p>1 x Mobile developer</p>
          <p>1 x UI/UX designer</p>
          <p>1 x Scrum Master</p>
          <p>1 x QA specialists</p>
        </div>
      ),
    },
  ];
  return (
    <MainLayout>
      <div className="relative">
        <section
          className="lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <div className="text-6xl lg:text-9xl font-semibold text-dark lg:tracking-wider text-center">
            Crew Application
          </div>

          <div className="mt-12 lg:mb-0 mb-20">
            <div className="lg:text-2xl text-xl tracking-wide leading-10 text-center">
              Frontline Operations Application
            </div>
          </div>
        </section>

        <section
          className="lg:mt-40 mt-2"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <img src={Crew1} alt="illustration" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Details</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              {details.map((item, index) => (
                <div
                  className={`grid lg:grid-cols-12 grid-cols-1 gap-10 ${
                    index !== details.length - 1 ? "divider" : "pt-8"
                  }`}
                  key={index}
                >
                  <div className="text-lg tracking-wide leading-8 font-semibold col-span-4">
                    {item.title}
                  </div>
                  <div className="text-content">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          className="bg-white py-20 px-20"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>

        <section
          className="bg-primary lg:pt-20 lg:pb-10 lg:px-20 pt-20 px-10 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="flex lg:flex-row flex-col justify-between">
            <div>
              <div className="lg:text-3xl text-2xl">
                Beside Nimbus Blue that I mentioned in the previous section,
                we're also Nimbus Crew App that connected with Blue dashboard
                and used by our crew members to do their daily tasks.
                <br />
                <br />
                Nimbus for Work is an app for managing all of your shift
                workers. See all of your workers checkin locations, get notified
                when they are late, chat to them directly, and rate their work.
              </div>
            </div>
          </div>
          <img src={Crew2} alt="illustration" className="mt-20" />
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">About technologies</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Two Applications: Nimbus Crew and Nimbus Customer with backend
                coded by Elixir
              </div>

              <ul className="list-disc text-content lg:ml-20 ml-4 mt-8">
                <li className="mb-8">
                  Typescript used for whole projects. We decided to use because
                  it's a superset of Javascript and it is a great tool for large
                  scale projects.
                </li>
                <li className="mb-8">
                  React native is the main library UI for dashboard.
                </li>
                <li className="mb-8">
                  Expo - expands the API surface of React Native
                </li>
                <li className="mb-8">
                  Apollo Client is used for GraphQL queries and mutations.
                </li>
                <li className="mb-8">Lottie – graphic animations.</li>
                <li className="mb-8">
                  Google Maps Platform APIs, Firebase also included.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading">Project’s highlights</div>

          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                Nimbus Crew App
              </div>
              <div className="text-content">
                An app for our crew to check in & out of their shifts, see their
                payslips, apply for leave, chat to their colleagues, and track
                attendance bonuses. It's also for our "on the ground" managers
                to monitor the staff from anywhere and make quick changes
                whenever there are problems.
                <br />
                <br />
                <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                  Nimbus Customer App
                </div>
                An app that allows our customers to see when cleaners, temp
                staff, and contractors are arriving and leaving, book new
                services (ad-hoc cleaning, event support, pest control etc),
                chat to Nimbus staff and managers, and pay their invoices.
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-20">
            <img src={Crew3} alt="blue-mockup-3" className="mt-8" />
            <img src={Crew4} alt="blue-mockup-3" className="mt-8" />
          </div>
          <img src={Crew5} alt="blue-mockup-3" className="mt-8" />
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
            <img
              src={Crew8}
              alt="blue-mockup-3"
              className="mt-8 h-full object-cover"
            />
            <img
              src={Crew14}
              alt="blue-mockup-3"
              className="mt-8 h-full object-cover"
            />
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-8">
            <img src={Crew9} alt="blue-mockup-3" className="mt-8" />
            <img src={Crew7} alt="blue-mockup-3" className="mt-8" />
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
            <img
              src={Crew10}
              alt="blue-mockup-3"
              className="mt-8 h-full object-cover"
            />
            <img src={Crew11} alt="blue-mockup-3" className="mt-8" />
          </div>
        </section>

        <section
          className="bg-white lg:pt-40 lg:px-20 pt-20 px-10"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        >
          <div className="heading mb-20">Final thoughts</div>
          <div className="mt-20 grid lg:grid-cols-12 grid-cols-1 gap-10">
            <div className="lg:col-start-4 lg:col-span-12">
              <div className="text-lg tracking-wide leading-8 font-semibold mb-4">
                My Challenges & Solutions
              </div>
              <div className="text-content">
                After trying to learn and work with React Native on previous
                projects of Fulfilled that I mentioned before, I've confidence
                to continue with them. Although it's started with Expo but I
                think it's not matter cause I can it fast and easy to build the
                app.
                <br />
                <br />
                Me and the team have a lot of challenges with the projects since
                it's started with Supabase - the open source Firebase
                alternative. So we just coded on frontend tier. Then the project
                more become complex and we need to add more features. So we
                decided to add backend tier coded with Elixir, Phoenix, and
                Postgres. Then We must to re-build the app from scratch.
                <br />
                <br />
                Because the class of people using these applications is in the
                non-young age group (mostly 40+), so we need to make sure it
                easy to use and understand from the UI and UX perspective.
                Thanks to the Crews of the company, they are very helpful with
                feedback and suggestions for us to improve UI/UX and features.
                In the future, A/B testing will be done to make the
                feature-delivery process more efficient.
                <br />
                <br />
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
                  <img src={Crew12} alt="blue-mockup-3" className="mt-8" />
                  <img src={Crew13} alt="blue-mockup-3" className="mt-8" />
                </div>
                <br />
                <br /> I'm felt so happy because with that product, we helped
                alot of blue-collar people that worked on cleaning services,
                make their's job more easier and more happy at work. More than
                that, after worked on that projects with the solid techical
                team, I've learned a lot about building the app with React
                Native also learn how to build the structure that can be scale
                up and easy to maintain. I also improved my UI/UX design skills
                and have more confidence to chat with the end-user to get
                their's feedback.
              </div>
            </div>
          </div>
        </section>

        <section
          className="bg-white pt-40 px-20 text-white"
          style={{
            zIndex: 2,
            position: "sticky",
          }}
        ></section>
      </div>
    </MainLayout>
  );
};

export default CrewApp;
